
import { defineComponent } from 'vue';
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import BaseError from '@/components/ui/BaseError.vue';
import { CertificateTypes, ErrorFile } from '@/models/CertificateModel';
import certificates from '@/services/certificates';
import BaseDatePicker from '@/components/ui/BaseDatePicker.vue';
import { store } from '@/store';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';
import SmallSpinner from '@/components/SmallSpinner.vue';
import Spinner from '@/components/Spinner.vue';

export default defineComponent({
  name: 'UploadDeclarationArticles',
  components: {
    Spinner,
    SmallSpinner,
    BaseDatePicker,
    BaseError,
    ModalWrapper
  },
  props: {
    certificateType: {
      type: String,
      default: () => CertificateTypes.REFUSAL
    },
    pgId: {
      type: Number,
      default: () => null
    }
  },
  data: () => {
    return {
      requestData: {
        files: null,
      } as any,
      isLoading: false,
      errors: [],
    }
  },
  computed: {
    isDisabled(): boolean {
      const requiredValues = [
        this.requestData.files,
      ]

      return requiredValues.some((value: any) => !value)
    },
    uploadFileErrors(): Array<ErrorFile> {
      return this.$store.getters.getUploadFileErrors
    }
  },
  methods: {
    async downloadTemplate() {
      if (this.certificateType === CertificateTypes.REFUSAL) {
        const table = await certificates.refusalExcelTemplateArticles() as any
        await this.$store.dispatch('exportFile', {
          data: table,
          fileName: `template.xls`
        })

      } else if (this.certificateType === CertificateTypes.DECLARATION) {
        const table = await certificates.declarationExcelTemplate() as any
        await this.$store.dispatch('exportFile', {
          data: table,
          fileName: `template.xls`
        })
      }
    },
    async upload() {
      this.errors = []

      const formData = new FormData()
      this.isLoading = true

      formData.append('file', this.requestData.files[0])

      try {
        let response

        if (this.certificateType === CertificateTypes.REFUSAL && this.pgId) {
          formData.append('type', this.certificateType)
          formData.append('pgId', String(this.pgId))
          response = await certificates.uploadArticlesToRefusalProductGroup(formData)
        } else if (this.certificateType === CertificateTypes.DECLARATION) {
          response = await certificates.uploadArticlesToDeclarationProductGroup(formData)
        }

        if (response.length) {
          this.errors = response
          throw new Error(response)
        }
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS');
      } catch (e) {
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
      } finally {
        this.setDefault()
        this.isLoading = false
      }
    },
    setFile(event: any) {
      this.requestData.files = event.target.files || event.dataTransfer.files;
    },
    setDefault() {
      const inputRef = this.$refs.fileInput as InstanceType<typeof HTMLInputElement>
      inputRef.value = ''

      Object.keys(this.requestData)
        .map((key: string) => {
          this.requestData[key] = null
        })
    },
  }
})
