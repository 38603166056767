
import { defineComponent } from 'vue';
import BasePagination from '@/components/ui/BasePagination.vue';
import { CertificateProductGroup, CertificateTypes } from '@/models/CertificateModel';
import ToggleItem from '@/components/commodity-group-tree/ToggleItem.vue';
import DropdownEllipsis from '@/components/admin-panel/DropdownEllipsis.vue';
import BaseUpdateItemModal from '@/components/modals/base-modals/BaseUpdateItemModal.vue';
import CertificateTypesCheckboxes from '@/components/certificates/CertificateTypesCheckboxes.vue';
import UploadDeclarationGroups from '@/components/modals/UploadDeclarationGroups.vue';
import certificates from '@/services/certificates';
import BaseTable from '@/components/ui/BaseTable.vue';
import BaseCheckbox from '@/components/ui/BaseCheckbox.vue';
import UploadDeclarationArticles from '@/components/modals/UploadDeclarationArticlesModal.vue';
import SearchDropdown from '@/components/search/SearchDropdown.vue';

export default defineComponent({
  name: 'ProductGroupsCertificates',
  components: {
    SearchDropdown,
    UploadDeclarationArticles,
    BaseCheckbox,
    BaseTable,
    UploadDeclarationGroups,
    CertificateTypesCheckboxes,
    BaseUpdateItemModal,
    BasePagination,
    ToggleItem,
    DropdownEllipsis,
  },
  data: () => ({
    searchedText: '',
    pageable: {
      pageNumber: 0,
      pageSize: 30,
      totalPages: null
    },
    productGroupData: {
      name: null,
    } as any,
    productSubGroupData: {
      pgId: null,
      name: null,
      code: null
    } as any,
    checkedType: CertificateTypes.CERTIFICATE as keyof typeof CertificateTypes,
    declarationItems: [] as any,
    chosenPgId: 0,
  }),
  computed: {
    dropdownOptions(): any {
      return  (item: any) => [
        {
          name: 'Загрузить артикулы',
          method: () => this.openUploadDeclarationArticlesModal(item)
        }
      ]
    },
    headers(): any {
      return [
        {
          title: 'Код',
          content: (item: any) => item.code
        },
        {
          title: 'Название',
          content: (item: any) => item.name
        },
      ]
    },
    checkedTypeIsDeclaration(): boolean {
      return this.checkedType === CertificateTypes.DECLARATION
    },
    checkedTypeIsCertificate(): boolean {
      return this.checkedType === CertificateTypes.CERTIFICATE
    },
    productGroupNameWithCode() {
      return (item: any) => `${this.productGroupName(item)} (Код ТН ВЭД ЕАЭС: ${item.code || '-'})`
    },
    productGroupName() {
      return (item: any) => item.name.length > 100 ? `${item.name.slice(0, 100)}...` : item.name
    },
    getFieldName() {
      return (field: any) => {
        switch (field) {
          case 'name':
            return 'Название'
          case 'code':
            return 'Код ТН ВЭД ЕАЭС'
        }
      }
    },
    modalTitleProductGroup(): string {
      return this.isEditProductGroup
        ? 'Редактирование товарной группы'
        : 'Добавление товарной группы'
    },
    modalTitleProductSubGroup(): string {
      return this.isEditProductSubGroup
        ? 'Редактирование подгруппы'
        : 'Добавление подгруппы'
    },
    isEditProductGroup(): boolean {
      return this.productGroupData && this.productGroupData.id
    },
    isEditProductSubGroup(): boolean {
      return this.productSubGroupData && this.productSubGroupData.id
    },
    productGroups(): Array<CertificateProductGroup> {
      return this.$store.getters.getCertificateProductGroups
    }
  },
  methods: {
    async searchItems(inputValue: string) {
      if (inputValue?.length) {
        if (this.checkedType === CertificateTypes.CERTIFICATE) {
          const result = await certificates.searchDeclarationsByType(inputValue, this.checkedType)
          this.$store.commit('setCertificateProductGroups', result)
        } else if (this.checkedType === CertificateTypes.REFUSAL) {
          this.declarationItems = await certificates.searchRefusalProductGroups(inputValue)
        } else if (this.checkedType === CertificateTypes.DECLARATION) {
          this.declarationItems = await certificates.searchDeclarationProductGroups(inputValue)
        }
      } else {
        await this.getItems({ pageNumber: 0 })
      }
      this.searchedText = inputValue || ''
    },
    openUploadDeclarationArticlesModal(item?: any) {
      if (item) {
        this.chosenPgId = item.id
      }
      this.openModal('modal-upload-declaration-articles')
    },
    openUploadDeclarationModal() {
      this.openModal('modal-upload-declaration')
    },
    reselectCertificateType(chosenType: CertificateTypes) {
      this.checkedType = chosenType
      this.getItems({ pageNumber: 0 })
    },
    async updateProductSubGroup({ name, code, pgId, id }: any) {
      const dispatchAction = this.isEditProductSubGroup
        ? 'updateCertificateProductSubGroup'
        : 'addCertificateProductSubGroup'

      const payload = {
        pgId: this.productSubGroupData.pgId,
        name,
        code,
        ...(id && { id })
      }

      await this.$store.dispatch(dispatchAction, payload).finally(() => {
        this.setDefault()
        this.closeModal(`modal-update-product-sub-group`)
      })
    },
    setDefault() {
      this.productSubGroupData = { name: null, pgId: null, code: null}
      this.productGroupData = { name: null }
    },
    async updateProductGroup({ name, id }: any) {
      const dispatchAction = this.isEditProductGroup
        ? 'updateCertificateProductGroup'
        : 'addCertificateProductGroup'

      const payload = {
        name,
        ...(id && { id }),
        type: this.checkedType
      }

      await this.$store.dispatch(dispatchAction, payload).finally(() => {
        this.setDefault()
        this.closeModal(`modal-base-update-item`)
      })
    },
    async deleteProductGroup(item: any) {
      await this.$store.dispatch('deleteCertificateProductGroup', item.id)
    },
    editProductGroup({ name, id }: any) {
      this.openModal('modal-base-update-item')
      this.productGroupData = { name, id }
    },
    async deleteProductSubGroup(item: any) {
      await this.$store.dispatch('deleteCertificateProductSubGroup', item)
    },
    editProductSubGroup(productSubGroup: any, productGroupId: any) {
      this.productSubGroupData.pgId = productGroupId;
      this.openModal('modal-update-product-sub-group')
      this.productSubGroupData = productSubGroup
    },
    closeModal(id: string) {
      const modal = document.getElementById(id)!
      modal.style.display = 'none'
      this.setDefault()
    },
    openModal(id: string) {
      const modal = document.getElementById(id)!
      modal.style.display = 'flex'
    },
    async getItems(nextPageable: { pageNumber: number }) {
      const payload = {
        ...nextPageable,
        pageSize: this.pageable.pageSize,
      }

      let response;

      if (this.checkedType === CertificateTypes.REFUSAL) {
        response = await certificates.getRefusalProductGroups(nextPageable.pageNumber, payload.pageSize)
      } else if (this.checkedType === CertificateTypes.DECLARATION) {
        response = await certificates.getDeclarationsProductGroups(nextPageable.pageNumber, payload.pageSize)
      } else if (this.checkedType === CertificateTypes.CERTIFICATE) {
        response = await this.$store.dispatch('fetchCertificateProductGroups', payload)
      }

      this.declarationItems = response.content

      this.pageable = {
        ...this.pageable,
        pageNumber: response.pageable.pageNumber,
        totalPages: response.totalPages
      }
    }
  },
  watch: {
    checkedType() {
      this.searchedText = ''
    }
  },
  async mounted() {
    await certificates.searchCertificatesByPrefix('Колодки с накладками в сборе')
    await this.getItems(this.pageable)
  },
})
