
import { defineComponent } from 'vue';
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import BaseError from '@/components/ui/BaseError.vue';
import { CertificateTypes, ErrorFile } from '@/models/CertificateModel';
import certificates from '@/services/certificates';
import BaseDatePicker from '@/components/ui/BaseDatePicker.vue';
import { convertSimpleDate } from '@/components/converters/date';
import { store } from '@/store';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';
import SmallSpinner from '@/components/SmallSpinner.vue';
import Spinner from '@/components/Spinner.vue';

export default defineComponent({
  name: 'UploadDeclarationGroups',
  components: {
    Spinner,
    SmallSpinner,
    BaseDatePicker,
    BaseError,
    ModalWrapper
  },
  props: {
    certificateType: {
      type: String,
      default: () => CertificateTypes.REFUSAL
    }
  },
  data: () => {
    return {
      errors: [],
      requestData: {
        files: null,
        startDate: {
          start: null,
          end: null,
        },
        code: null,
      } as any,
      isLoading: false,
    }
  },
  computed: {
    isDisabled(): boolean {
      const requiredValues = [ this.requestData.files ]

      if (!this.isDeclarationType) {
        requiredValues.push(
          this.requestData.startDate.start,
          this.requestData.code
        )
      }

      return requiredValues.some((value: any) => !value)
    },
    isDeclarationType(): boolean {
      return this.certificateType === CertificateTypes.DECLARATION
    }
  },
  methods: {
    async downloadTemplate() {
      const table = await certificates.refusalExcelTemplateGroup() as any
      const blob = new Blob([table], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }) as any;
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `template.xls`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async upload() {
      this.errors = []
      this.isLoading = true

      try {
        let response
        if (this.certificateType === CertificateTypes.REFUSAL) {
          const formData = new FormData()
          formData.append('file', this.requestData.files[0])
          formData.append('type', this.certificateType)
          formData.append('code', this.requestData.code)
          formData.append("datetime", convertSimpleDate(new Date(this.requestData.startDate.start)));

          response = await certificates.uploadRefusalProductGroup(formData)
        } else if (this.certificateType === CertificateTypes.DECLARATION) {
          const formData = new FormData()
          formData.append('file', this.requestData.files[0])

          response = await certificates.uploadDeclarationProductGroup(formData)
        }

        if (response.length) {
          this.errors = response
          throw new Error(response)
        }

        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS');
      } catch (e) {
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
      } finally {
        this.setDefault()
        this.isLoading = false
        this.$emit('updateItems')
      }
    },
    setFile(event: any) {
      this.requestData.files = event.target.files || event.dataTransfer.files;
    },
    setDefault() {
      const inputRef = this.$refs.fileInput as InstanceType<typeof HTMLInputElement>
      inputRef.value = ''

      Object.keys(this.requestData)
        .map((key: string) => {
          this.requestData[key] = null
        })

      this.requestData.startDate = { start: null, end: null }
    },
  }
})
